html, body {
    --mdc-theme-primary: #2199E8;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    font-family: 'Helvetica Neue', arial, sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media (prefers-color-scheme: dark) {
    html, body {
        --mdc-theme-background: rgba(0, 0, 0, 1);
        --mdc-theme-surface: rgba(255, 255, 255, 0.1);
        --mdc-theme-text-primary-on-background: white;

        background-color: var(--mdc-theme-background);
        color: var(--mdc-theme-text-primary-on-background)
    }

    body > div {
        background-color: var(--mdc-theme-background);
    }

    .mdc-list-item {
        color: var(--mdc-theme-text-primary-on-background) !important;
    }

    .mdc-menu-surface {
        background-color: var(--mdc-theme-background) !important;
    }

    .mdc-select:not(.mdc-select--disabled) .mdc-select__anchor {
        background-color: var(--mdc-theme-background) !important;
    }

    .mdc-select:not(.mdc-select--disabled) .mdc-select__selected-text {
        color: var(--mdc-theme-text-primary-on-background) !important;
    }

    .mdc-floating-label {
        color: var(--mdc-theme-text-primary-on-background) !important;
    }

    .mdc-card__action--icon:not(:disabled) {
        color: var(--mdc-theme-text-primary-on-background) !important;
    }

    .react-calendar {
        background-color: var(--mdc-theme-surface) !important;
    }

    .react-calendar * {
        color: var(--mdc-theme-text-primary-on-background) !important;
    }

    .react-calendar__tile:disabled {
        background-color: var(--mdc-theme-background) !important;
    }

    .react-calendar__month-view__weekdays {
        background-color: var(--mdc-theme-background);
    }

    .react-calendar__navigation button[disabled] {
        background-color: var(--mdc-theme-background) !important;
    }

    .react-calendar__navigation button {
        background-color: var(--mdc-theme-surface) !important;
    }
}

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v85/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format('woff2');
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

body > div {
    display: flex;
    flex-flow: column;
    align-self: center;
}

h1 {
    align-self: center;
}

.mdc-top-app-bar--fixed-adjust {
    margin-bottom: 1rem;
}

.mdc-select * {
    width: 100%;
}

.mdc-select__anchor {
    margin-top: 1rem;
}

.mdc-select .mdc-select__dropdown-icon {
    filter: grayscale(1);
}

.mdc-linear-progress {
    margin-top: 2rem !important;
    margin-bottom: 1rem;
}

.mdc-card {
    align-self: center;
    padding: 10px;
    width: 90%;
    margin: 0 10px 1rem 10px;
}

.react-calendar__tile--active {
    background: var(--mdc-theme-primary) !important;
}

.mdc-card__media, div > video {
    margin-top: 1rem;
}

.card-media > img {
    width: 100%
}

.react-calendar {
    border: none !important;
    width: 100% !important;
}
